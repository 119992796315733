import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Park({ styles, ...props }) {
  const Elements = useComponents()

  const background_color = styles.forElement("background").backgroundColor
  const checkboxes_color = styles.forElement("content_text").color

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
    >
      <div
        className="formkit-background"
        style={{ backgroundColor: background_color }}
      />
      <div
        className="formkit-background"
        style={styles.forElement("background")}
      />
      <div className="formkit-container">
        <Elements.Image
          className="formkit-image"
          name="image"
          size={{ h: 500, w: 500 }}
        />
        <div>
          <header>
            <Elements.Heading
              className="formkit-heading formkit-heading-primary"
              name="header"
              defaults={{
                content: "Catchy headline goes here."
              }}
            />
            <Elements.Heading
              className="formkit-subheader formkit-heading formkit-heading-secondary"
              name="subheader"
              tag={"h2"}
              defaults={{
                content: "Short intro paragraph goes here."
              }}
            />
          </header>
          <Elements.Region name="content" className="formkit-content">
            <Elements.Content
              defaults={{
                content:
                  "Provide some more detail in this content area. Vestibulum ut ornare lobortis fermentum a consectetur etiam adipiscing litora consectetur dis lorem parturient himenaeos at ridiculus duis at a ad ridiculus parturient ipsum enim lorem scelerisque.Facilisis ad vestibulum lectus tempus curae penatibus non mattis nascetur dui leo fusce a hac a nisi.Duis eu sed et a iaculis vivamus quam diam in leo."
              }}
            />
          </Elements.Region>
          <div className="formkit-card" style={styles.forElement("card")}>
            <Elements.Form>
              <Elements.Errors />
              <Elements.CustomFields style={{ color: checkboxes_color }}>
                <Elements.AddFieldButton />
                <Elements.Button
                  name="submit"
                  group="button"
                  defaults={{ content: "Get a free ebook" }}
                />
              </Elements.CustomFields>
            </Elements.Form>
            <Elements.Content
              name="disclaimer"
              defaults={{
                content: "We respect your privacy. Unsubscribe at any time."
              }}
            />
          </div>
        </div>

        <Elements.Content
          name="copyright"
          defaults={{
            content: `All rights reserved`
          }}
        />
        <Elements.BuiltWith background="background" image="background" />
      </div>
    </Elements.LandingPage>
  )
}

Park.style = "landing_page"
Park.categories = ["Podcast", "Product"]
Park.thumbnail = ""
Park.preview = "https://demo.ck.page/park"
Park.fields = [
  {
    name: "first_name",
    label: "Your first name"
  },
  {
    name: "email_address",
    label: "Your email address"
  }
]

export default createTemplate(Park, { name: "Park" })
